import {NavigationPath} from '@wix/crypto-nft-view-sdk';
import React from 'react';
import {Breadcrumbs as TPABreadcrumbs} from 'wix-ui-tpa';
import {useStores} from '../../../../../hooks/useStores';

const Breadcrumbs: React.FC = (prop) => {
  const {navigationPath, onNavigationBreadcrumClicked} = useStores();
  if (!navigationPath) {
    return null;
  }

  const handleNavigationBreadcrumClicked = (n: NavigationPath) => {
    onNavigationBreadcrumClicked && onNavigationBreadcrumClicked(n.href);
  };

  return <TPABreadcrumbs onClick={(n: any) => handleNavigationBreadcrumClicked(n)} items={navigationPath} />;
};

export default Breadcrumbs;
