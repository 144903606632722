import {CopyableTrimmedToken, TrimmedToken} from '@wix/crypto-nft-common-components';
import React from 'react';
import s from './DetailsTab.scss';
import {useStores} from '../../../../../../../../hooks/useStores';
import {Blockchain} from '@wix/ambassador-crypto-nft-v1-collection/build/cjs/types';

export const DetailsTab: React.FC = () => {
  const {digitalAsset, collection, copyToClipboard} = useStores();

  const contractAddressLink =
    collection.blockchain === Blockchain.ETHEREUM
      ? 'https://etherscan.io/token/'
      : 'https://rinkeby.etherscan.io/token/';

  const detailsAttributes = [
    {
      key: 'Contract Address',
      value: (
        <a
          className={s.link}
          rel="noreferrer"
          target="_blank"
          href={`${contractAddressLink}${collection?.contractAddress}`}>
          <TrimmedToken token={collection?.contractAddress || ''} />
        </a>
      ),
    },
    {
      key: 'Token ID',
      value: (
        <CopyableTrimmedToken
          className={s.link}
          token={digitalAsset?.tokenId || ''}
          copyToClipboard={() => copyToClipboard && copyToClipboard(digitalAsset?.tokenId || '')}
          appendTo="viewport"
        />
      ),
    },
    {key: 'Token Standard', value: collection?.standard},
    {key: 'Blockchain', value: collection?.blockchain},
    {key: 'Metadata', value: 'IPFS'},
  ];

  return (
    <ul>
      {detailsAttributes.map(({key, value}, i) => (
        <li className={s.contentRow} key={i}>
          <span className={s.key}>{key}</span>
          <span className={s.value}>{value}</span>
        </li>
      ))}
    </ul>
  );
};
