import React from 'react';
import {Image, ImageResizeOptions} from 'wix-ui-tpa';
import {classes} from './AssetPageImage.st.css';
import {useStores} from '../../../../../../../hooks/useStores';

export const AssetPageImage: React.FC<{className: string}> = ({className}) => {
  const {imageUrl} = useStores();

  if (!imageUrl) {
    return null;
  }

  return (
    <Image
      resize={ImageResizeOptions.contain}
      className={`${classes.image} ${className}`}
      src={imageUrl}
      fluid={true}
      alt="asset"
    />
  );
};
