import React from 'react';
import s from './EmptyState.scss';
import {useStores} from '../../../../../hooks/useStores';

const EmptyState: React.FC = () => {
  const {galleryUrl, onContinueShoppingClicked} = useStores();

  const handleContinueShoppingClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onContinueShoppingClicked && onContinueShoppingClicked();
  };

  return (
    <div className={s.container}>
      <span className={s.headline}>This asset couldn't be found</span>
      {galleryUrl ? (
        <a onClick={(e) => handleContinueShoppingClicked(e)} className={s.continue} href={galleryUrl}>
          Continue Shopping
        </a>
      ) : null}
    </div>
  );
};

export default EmptyState;
