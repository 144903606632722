import {StyleParamType, wixColorParam, wixFontParam, createStylesParams} from '@wix/tpa-settings';

export type IStylesParams = {
  cardsLayout: StyleParamType.Boolean;
  backgroundColor: StyleParamType.Color;
  itemNameColor: StyleParamType.Color;
  itemNameFont: StyleParamType.Font;
  ownerWalletNameColor: StyleParamType.Color;
  ownerWalletNameFont: StyleParamType.Font;
  royaltyAmountColor: StyleParamType.Color;
  royaltyAmountFont: StyleParamType.Font;
  itemPriceColor: StyleParamType.Color;
  itemPriceFont: StyleParamType.Font;
  itemDescriptionColor: StyleParamType.Color;
  itemDescriptionFont: StyleParamType.Font;
  buyNowButtonColor: StyleParamType.Color;
  buyNowButtonCornerRadius: StyleParamType.Number;
  buyNowButtonBorderColor: StyleParamType.Color;
  buyNowButtonBorderWidth: StyleParamType.Number;
  buyNowButtonTextFont: StyleParamType.Font;
  buyNowButtonTextColor: StyleParamType.Color;
  detailsTitleFont: StyleParamType.Font;
  detailsTitleColor: StyleParamType.Color;
  detailsTextFont: StyleParamType.Font;
  detailsTextColor: StyleParamType.Color;
  propertiesTitleFont: StyleParamType.Font;
  propertiesTitleColor: StyleParamType.Color;
  propertiesTextFont: StyleParamType.Font;
  propertiesTextColor: StyleParamType.Color;
  assetImageBorderColor: StyleParamType.Color;
  assetImageBorderWidth: StyleParamType.Number;
  assetImageBorderCornerRadius: StyleParamType.Number;
  propertiesBorderColor: StyleParamType.Color;
  propertiesBorderWidth: StyleParamType.Number;
  propertiesBorderCornerRadius: StyleParamType.Number;
  cardsLayoutBackgroundColor: StyleParamType.Color;
  cardsLayoutBorderColor: StyleParamType.Color;
  cardsLayoutBorderWidth: StyleParamType.Number;
  cardsLayoutCornerRadius: StyleParamType.Number;
  fullWidth: StyleParamType.Boolean;
};

export default createStylesParams<IStylesParams>({
  cardsLayout: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => {
      return false;
    },
  },
  fullWidth: {
    type: StyleParamType.Boolean,
  },
  assetImageBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  assetImageBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  assetImageBorderCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  itemNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M'),
  },
  ownerWalletNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  ownerWalletNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  royaltyAmountColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  royaltyAmountFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  itemPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemPriceFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  itemDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  buyNowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buyNowButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buyNowButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  buyNowButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buyNowButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  buyNowButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  detailsTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  detailsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  detailsTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  propertiesTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  propertiesTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  propertiesTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  propertiesTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  propertiesBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  propertiesBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 1;
    },
  },
  propertiesBorderCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  cardsLayoutBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3', 0),
  },
  cardsLayoutBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  cardsLayoutBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 1;
    },
  },
  cardsLayoutCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
});
