import React from 'react';
import {AssetDetailsContractDetails} from '../../Common/AssetDetailsContractDetails/AssetDetailsContractDetails';
import {AssetDetailsDescription} from '../../Common/AssetDetailsDescription/AssetDetailsDescription';
import {AssetDetailsTitle} from '../../Common/AssetDetailsTitle/AssetDetailsTitle';
import {AssetPageImage} from '../../Common/AssetPageImage/AssetPageImage';
import {BuyNowButton} from '../../Common/BuyNowButton/BuyNowButton';
import Price from '../../Common/Price/Price';
import s from './AssetDetailsDesktopLayout.scss';
import {useSettings, useStyles} from '@wix/tpa-settings/react';
import settingsParams from '../../../../../settingsParams';
import {AssetInfoTabs} from '../../Common/AssetInfoTabs/AssetInfoTabs';
import stylesParams from '../../../../../stylesParams';
import {Divider} from 'wix-ui-tpa';
import cx from 'classnames';
import {useStores} from '../../../../../../../hooks/useStores';
import {useDescriptionSettings} from '../../../../../../../hooks/useDescriptionSettings';
import Quantity from '../../Common/Quantity/Quantity';

export const AssetDetailsDesktopLayout: React.FC = () => {
  const {digitalAsset, isSold} = useStores();
  const {priceData} = digitalAsset!;
  const styles = useStyles();
  const settings = useSettings();
  const showDetailsSection = settings.get(settingsParams.showDetailsSection);
  const showPrice = settings.get(settingsParams.showItemPrice);
  const cardsLayout = styles.get(stylesParams.cardsLayout);
  const {shouldShowDescriptionInfo} = useDescriptionSettings();

  const classNames = cx(s.assetDetailsContainer, {
    [s.cardsLayout]: styles.get(stylesParams.cardsLayout),
    [s.fullWidth]: styles.get(stylesParams.fullWidth),
  });

  return (
    <div className={classNames}>
      <div className={s.imageWrapper}>
        <AssetPageImage className={s.image} />
      </div>
      <div className={s.assetDetailsSectionWrapper}>
        <div className={s.assetDetailsTop}>
          <div>
            <div className={s.title}>
              <AssetDetailsTitle />
            </div>
            <AssetDetailsContractDetails className={s.merchantWalletAddress} royaltyClassName={s.royaltyClassName} />
          </div>
          {showPrice && <Price className={s.price} price={priceData?.price || ''} />}
          {shouldShowDescriptionInfo && (
            <>
              {cardsLayout && <Divider className={s.divider} />}
              <AssetDetailsDescription className={s.description} />
            </>
          )}
          <Quantity quantity={digitalAsset?.quantity} className={`${!cardsLayout ? s.nonCardQuantity : ''}`} />
        </div>
        <BuyNowButton isSold={isSold} className={s.buyNowButton} />
        {showDetailsSection && <AssetInfoTabs />}
      </div>
    </div>
  );
};
