import React from 'react';
import {Quantity as TQuantity} from '@wix/ambassador-crypto-nft-v1-digital-asset/types';
import s from './Quantity.scss';

const Quantity: React.FC<{quantity: TQuantity | undefined; className?: string}> = ({quantity, className}) => {
  if (!quantity) {
    return null;
  }

  if (quantity.total === 1) {
    return null;
  }

  const available = quantity.total! - quantity.sold!;
  if (!available) {
    return null;
  }

  return (
    <div className={`${s.quantityWrapper} ${className}`}>
      <span>{`${available} of ${quantity.total} available`}</span>
    </div>
  );
};

export default Quantity;
