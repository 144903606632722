import React from 'react';

export const PropertiesIcon = ({className}: {className?: string}) => (
  <div style={{display: 'flex', alignItems: 'center'}} className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4998 6.00098C18.3268 6.00098 18.9998 6.67398 18.9998 7.50098V11.659C18.9998 12.186 18.7867 12.701 18.4137 13.074L12.9248 18.562C12.6428 18.845 12.2657 19.001 11.8647 19.001C11.4637 19.001 11.0868 18.845 10.8038 18.562L6.43875 14.197C5.85375 13.612 5.85375 12.661 6.43875 12.076L11.9278 6.58698C12.3058 6.20898 12.8078 6.00098 13.3418 6.00098H17.4998ZM17.4998 7.00098H13.3418C13.0748 7.00098 12.8238 7.10498 12.6348 7.29398L7.14575 12.783C6.95175 12.978 6.95175 13.295 7.14575 13.49L11.5107 17.855C11.7007 18.044 12.0308 18.044 12.2178 17.855L17.7067 12.367C17.8927 12.18 17.9998 11.922 17.9998 11.659V7.50098C17.9998 7.22498 17.7758 7.00098 17.4998 7.00098ZM14.2928 9.29428C14.6838 8.90328 15.3157 8.90328 15.7067 9.29428C16.0977 9.68528 16.0977 10.3173 15.7067 10.7083C15.3157 11.0993 14.6838 11.0993 14.2928 10.7083C13.9018 10.3173 13.9018 9.68528 14.2928 9.29428Z"
        fill="black"
      />
    </svg>
  </div>
);
