import React from 'react';
import {BuyNowButton} from '../../Common/BuyNowButton/BuyNowButton';
import {AssetDetailsDescription} from '../../Common/AssetDetailsDescription/AssetDetailsDescription';
import {AssetDetailsContractDetails} from '../../Common/AssetDetailsContractDetails/AssetDetailsContractDetails';
import {AssetDetailsTitle} from '../../Common/AssetDetailsTitle/AssetDetailsTitle';
import {AssetPageImage} from '../../Common/AssetPageImage/AssetPageImage';
import s from './AssetDetailsMobileLayout.scss';
import {useSettings, useStyles} from '@wix/tpa-settings/react';
import settingsParams from '../../../../../settingsParams';
import Price from '../../Common/Price/Price';
import {AssetInfoTabs} from '../../Common/AssetInfoTabs/AssetInfoTabs';
import stylesParams from '../../../../../stylesParams';
import {useStores} from '../../../../../../../hooks/useStores';
import {useDescriptionSettings} from '../../../../../../../hooks/useDescriptionSettings';
import Quantity from '../../Common/Quantity/Quantity';

export const AssetDetailsMobileLayout: React.FC = () => {
  const {digitalAsset, isSold} = useStores();
  const {priceData, quantity} = digitalAsset!;
  const styles = useStyles();
  const settings = useSettings();
  const showDetailsSection = settings.get(settingsParams.showDetailsSection);
  const showPrice = settings.get(settingsParams.showItemPrice);
  const {shouldShowDescriptionInfo} = useDescriptionSettings();
  const cardsLayout = styles.get(stylesParams.cardsLayout);

  return (
    <div className={s.assetDetailsContainer}>
      <div className={s.title}>
        <AssetDetailsTitle />
      </div>
      <AssetPageImage className={s.image} />
      <div>
        <AssetDetailsContractDetails className={s.contractDetails} royaltyClassName={s.royaltyClassName} />
        <div className={cardsLayout ? s.border : ''}>
          {showPrice && <Price className={s.price} price={priceData?.price || ''} />}
          <BuyNowButton isSold={isSold} />
          {shouldShowDescriptionInfo && <AssetDetailsDescription className={s.description} />}
          <Quantity quantity={quantity} className={`${!cardsLayout ? s.nonCardQuantity : ''}`} />
        </div>

        {showDetailsSection && <AssetInfoTabs />}
      </div>
    </div>
  );
};
