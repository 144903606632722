import React from 'react';
import s from './PropertyItem.scss';
import {Tooltip} from 'wix-ui-tpa';

export const PropertyItem: React.FC<{title: string; value: string}> = ({title, value}) => {
  return (
    <div tabIndex={0} className={s.itemContainer}>
      <div className={s.itemInfo}>
        <Tooltip content={title} appendTo="viewport">
          <div className={s.title}>{title}</div>
        </Tooltip>
        <Tooltip content={value} appendTo="viewport">
          <div className={s.value}>{value}</div>
        </Tooltip>
      </div>
    </div>
  );
};
