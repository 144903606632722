import React, {useState} from 'react';
import {TabsAlignment, TabsSkin, TabbedContent} from 'wix-ui-tpa';
import {PropertiesTab} from './PropertiesTab/PropertiesTab';
import s from './AssetInfoTabs.scss';
import {DetailsTab} from './DetailsTab/DetailsTab';
import {DetailsIcon} from '../../../../../../../assets/icons/Details';
import {PropertiesIcon} from '../../../../../../../assets/icons/Properties';
import {useStyles} from '@wix/tpa-settings/react';
import stylesParams from '../../../../../stylesParams';
import {DescriptionTab} from './DescriptionTab/DescriptionTab';
import {DescriptionIcon} from '../../../../../../../assets/icons/Description';
import {useStores} from '../../../../../../../hooks/useStores';
import {useDescriptionSettings} from '../../../../../../../hooks/useDescriptionSettings';

export const AssetInfoTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const styles = useStyles();
  const {shouldShowPropertiesTab} = useStores();
  const {shouldShowDescriptionTab} = useDescriptionSettings();
  const tabs = [<DetailsTab />];
  const tabItems = [{id: 'details', title: 'Details', prefix: <DetailsIcon />}];

  if (shouldShowPropertiesTab) {
    tabs.unshift(<PropertiesTab />);
    tabItems.unshift({id: 'properties', title: 'Properties', prefix: <PropertiesIcon />});
  }

  if (shouldShowDescriptionTab) {
    tabs.unshift(<DescriptionTab />);
    tabItems.unshift({id: 'description', title: 'Description', prefix: <DescriptionIcon />});
  }

  return (
    <div className={styles.get(stylesParams.cardsLayout) ? s.border : ''}>
      <TabbedContent
        className={s.tabs}
        items={tabItems}
        activeTabIndex={activeTab}
        onTabClick={(tab) => setActiveTab(tab)}
        skin={TabsSkin.fullUnderline}
        alignment={TabsAlignment.left}>
        {(id) => <div className={s.content}>{tabs[id]}</div>}
      </TabbedContent>
    </div>
  );
};
