import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings';
import applicationJson from '../../../.application.json';

export type ISettingsParams = {
  greetingsText: SettingsParamType.Text;
  buyNowButtonText: SettingsParamType.Text;
  showWalletAddress: SettingsParamType.Boolean;
  showRoyaltyAmount: SettingsParamType.Boolean;
  showItemPrice: SettingsParamType.Boolean;
  showNavigation: SettingsParamType.Boolean;
  showDescription: SettingsParamType.Boolean;
  showDescriptionTab: SettingsParamType.Boolean;
  showDetailsSection: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  greetingsText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => `${t('app.settings.defaults.greetingsPretext')} ${applicationJson.appName}`,
  },
  buyNowButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => `${t('app.settings.defaults.buyNowButtonText')}`,
  },
  showDetailsSection: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  showNavigation: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  showItemPrice: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  showRoyaltyAmount: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  showDescription: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  showDescriptionTab: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return false;
    },
  },
  showWalletAddress: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
});
