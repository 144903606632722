import React from 'react';

export const DetailsIcon = ({className}: {className?: string}) => (
  <div style={{display: 'flex', alignItems: 'center'}} className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5 18.001H7.5C7.224 18.001 7 17.777 7 17.501V7.50098C7 7.22498 7.224 7.00098 7.5 7.00098H11V10.501C11 11.328 11.673 12.001 12.5 12.001H16V17.501C16 17.777 15.776 18.001 15.5 18.001ZM12 7.70698L15.298 11.001H12.5C12.224 11.001 12 10.777 12 10.501V7.70698ZM16.56 10.848L12.146 6.43998C11.863 6.15698 11.487 6.00098 11.086 6.00098H7.5C6.673 6.00098 6 6.67398 6 7.50098V17.501C6 18.328 6.673 19.001 7.5 19.001H15.5C16.327 19.001 17 18.328 17 17.501V11.909C17 11.508 16.844 11.131 16.56 10.848Z"
        fill="black"
      />
    </svg>
  </div>
);
