import React from 'react';
import {AssetDetailsMobileLayout} from './Mobile/AssetDesktopMobileLayout/AssetDetailsMobileLayout';
import {AssetDetailsDesktopLayout} from './Desktop/AssetDetailsDesktopLayout/AssetDetailsDesktopLayout';
import {useStores} from '../../../../../hooks/useStores';

const AssetPageDetails: React.FC = (props) => {
  const {isMobile} = useStores();

  if (isMobile) {
    return <AssetDetailsMobileLayout />;
  }

  return <AssetDetailsDesktopLayout />;
};

export default AssetPageDetails;
