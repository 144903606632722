import React from 'react';
import s from './PropertiesTab.scss';
import {PropertyItem} from './PropertyItem/PropertyItem';
import {useStores} from '../../../../../../../../hooks/useStores';

export const PropertiesTab: React.FC = () => {
  const {digitalAsset} = useStores();

  if (!digitalAsset?.properties) {
    return null;
  }

  const data = Object.entries(digitalAsset.properties).map((pair) => {
    return {key: pair[0], value: pair[1]};
  });

  if (!data?.length) {
    return null;
  }

  return (
    <div className={s.content}>
      {data.map(({key, value}, i) => (
        <PropertyItem key={i} title={key} value={value} />
      ))}
    </div>
  );
};
