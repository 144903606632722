import React from 'react';
import {EtherIcon} from '../../../../../../../assets/icons/Ether';
import s from './Price.scss';

const Price: React.FC<{price: string; className?: string}> = ({price, className}) => {
  return (
    <div className={`${s.priceWrapper} ${className}`}>
      <EtherIcon />
      <span className={s.numericPrice}>{price}</span>
    </div>
  );
};

export default Price;
