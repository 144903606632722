import React from 'react';
import {CopyableTrimmedToken} from '@wix/crypto-nft-common-components';
import s from './AssetDetailsContractDetails.scss';
import {useSettings} from '@wix/tpa-settings/react';
import settingsParams from '../../../../../settingsParams';
import {useStores} from '../../../../../../../hooks/useStores';

export const AssetDetailsContractDetails: React.FC<{className?: string; royaltyClassName?: string}> = ({
  className,
  royaltyClassName,
}) => {
  const {digitalAsset, copyToClipboard} = useStores();
  const settings = useSettings();

  const showWallet = settings.get(settingsParams.showWalletAddress);
  const showRoyalty = settings.get(settingsParams.showRoyaltyAmount);

  if (!showWallet && !showWallet) {
    return null;
  }

  return (
    <div className={`${s.assetDetailsContractDetailsWrapper} ${className}`}>
      {showWallet ? (
        <div className={s.ownerWalletAddress}>
          <span className={s.ownerWalletAddressTitle}>Owner Address: </span>
          <CopyableTrimmedToken
            token={digitalAsset?.payoutWalletAddress || ''}
            copyToClipboard={() => copyToClipboard && copyToClipboard(digitalAsset?.payoutWalletAddress || '')}
          />
        </div>
      ) : undefined}
      {showRoyalty ? (
        <span className={`${s.royalty} ${royaltyClassName}`}>{digitalAsset?.royalty}% royalties</span>
      ) : undefined}
    </div>
  );
};
