import {useSettings} from '@wix/tpa-settings/react';
import * as React from 'react';
import {Button} from 'wix-ui-tpa';
import settingsParams from '../../../../../settingsParams';
import {classes} from './BuyNowButton.st.css';
import {useStores} from '../../../../../../../hooks/useStores';

export const BuyNowButton: React.FC<{isSold: boolean; className?: string}> = ({className, isSold}) => {
  const {onBuyNowButtonClicked} = useStores();
  const settings = useSettings();
  const buyNowText = isSold ? 'Sold' : settings.get(settingsParams.buyNowButtonText);
  return (
    <Button
      fullWidth
      disabled={isSold}
      className={`${classes.buyNowButton} ${className}`}
      onClick={() => onBuyNowButtonClicked && onBuyNowButtonClicked()}>
      {buyNowText}
    </Button>
  );
};
