import React from 'react';
import AssetPageDetails from '../AssetPageDetails/AssetPageDetails';
import Navigation from '../Navigation/Navigation';
import EmptyState from '../EmptyState/EmptyState';
import s from './AssetPageApp.scss';
import {useSettings} from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import {useEnvironment, useExperiments, useTranslation} from '@wix/yoshi-flow-editor';
import {useStores} from '../../../../../hooks/useStores';
import {PurchaseDialog} from '@wix/crypto-nft-purchase-dialog';

const AssetPageApp: React.FC = () => {
  const {
    isEmptyState,
    instance,
    metaSiteId,
    host,
    digitalAsset,
    copyToClipboard,
    onCloseModalClicked,
    isModalOpened,
    collection,
    isSsr,
    lastConnectedWallet,
    setLastConnectedWalletKey,
    removeLastConnectedWalletKey,
  } = useStores();

  const {experiments} = useExperiments();
  const settings = useSettings();
  const {isMobile} = useEnvironment();
  const {t} = useTranslation();

  const showNavigation = settings.get(settingsParams.showNavigation);

  return (
    <div data-hook="AssetPage-wrapper">
      <main className={s.root}>
        {showNavigation ? <Navigation /> : undefined}
        {isEmptyState ? <EmptyState /> : <AssetPageDetails />}
        {!isSsr && instance ? (
          <PurchaseDialog
            t={t}
            isMobile={isMobile}
            isDialogOpen={isModalOpened || false}
            onCloseDialog={() => {
              onCloseModalClicked && onCloseModalClicked();
            }}
            copyToClipboard={(s: string) => copyToClipboard && copyToClipboard(s)}
            digitalAsset={digitalAsset}
            compId={host?.id || ''}
            instance={instance}
            metaSiteId={metaSiteId || ''}
            collection={collection}
            lastConnectedWallet={lastConnectedWallet || ''}
            setLastConnectedWalletKey={setLastConnectedWalletKey as any}
            removeLastConnectedWalletKey={removeLastConnectedWalletKey as any}
            isBlockchainDisabled={experiments.enabled('specs.crypto.MockBlockchain')}
          />
        ) : undefined}
      </main>
    </div>
  );
};

export default AssetPageApp;
