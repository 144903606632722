import React, {FC} from 'react';
import {ExperimentsProvider, WidgetProps} from '@wix/yoshi-flow-editor';
import {AssetPageContext, AssetPageStoreProps} from './Store/AssetPageContext';
import AssetPageApp from './Components/AssetPageApp/AssetPageApp';

const Widget: FC<WidgetProps<AssetPageStoreProps>> = (props) => {
  return (
    <ExperimentsProvider>
      <AssetPageContext.Provider value={props}>
        <AssetPageApp />
      </AssetPageContext.Provider>
    </ExperimentsProvider>
  );
};

export default Widget;
