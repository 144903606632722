import React from 'react';
import {ArrowLeft} from '../../../../../assets/icons/ArrowLeft';
import {ArrowRight} from '../../../../../assets/icons/ArrowRight';
import s from './Navigation.scss';
import {useStores} from '../../../../../hooks/useStores';

interface LinkItem {
  href: string | undefined;
  handleContinueShoppingClicked: Function;
  rel: 'prev' | 'next';
}

const Link: React.FC<{linkItem: LinkItem}> = ({linkItem}) => {
  const {href, handleContinueShoppingClicked, rel} = linkItem;

  const disabled = !href;

  const content = (
    <>
      {rel === 'prev' ? (
        <>
          <ArrowLeft />
          Prev
        </>
      ) : (
        <>
          Next
          <ArrowRight />
        </>
      )}
    </>
  );

  return (
    <a
      rel={rel}
      href={href}
      className={`${s[rel]} ${disabled ? s.disabled : ''}`}
      onClick={(event) => handleContinueShoppingClicked(event, href)}>
      {content}
    </a>
  );
};

const Pagination: React.FC = (props) => {
  const {navigationAssetIds, digitalAsset, assetPageUrl, onPrevNextClicked} = useStores();

  const currentAssetIdIndex =
    navigationAssetIds && digitalAsset && navigationAssetIds?.findIndex((assetId) => digitalAsset?.id === assetId);
  const isValidIndex = typeof currentAssetIdIndex === 'number' && currentAssetIdIndex > -1;

  if (!isValidIndex || navigationAssetIds!.length < 2) {
    return null;
  }

  const handleContinueShoppingClicked = (event: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    event.preventDefault();
    event.stopPropagation();

    if (!url) {
      return;
    }

    onPrevNextClicked && onPrevNextClicked(url);
  };

  const nextAssetId = currentAssetIdIndex + 1 > -1 && navigationAssetIds![currentAssetIdIndex + 1];
  const prevAssetId = currentAssetIdIndex - 1 > -1 && navigationAssetIds![currentAssetIdIndex - 1];

  const nextItem: LinkItem = {
    href: nextAssetId ? `${assetPageUrl}/${nextAssetId}` : undefined,
    handleContinueShoppingClicked,
    rel: 'next',
  };

  const prevItem: LinkItem = {
    href: prevAssetId ? `${assetPageUrl}/${prevAssetId}` : undefined,
    handleContinueShoppingClicked,
    rel: 'prev',
  };

  return (
    <div className={s.pagination}>
      <Link linkItem={prevItem} />
      <Link linkItem={nextItem} />
    </div>
  );
};

export default Pagination;
