import React from 'react';
import NavigationBreadcrumbs from './Breadcrumbs';
import Pagination from './Pagination';
import s from './Navigation.scss';
import {useStores} from '../../../../../hooks/useStores';

const Navigation: React.FC = (props) => {
  const {isMobile} = useStores();

  return (
    <div className={s.navigation}>
      <NavigationBreadcrumbs />
      {!isMobile ? <Pagination /> : undefined}
    </div>
  );
};

export default Navigation;
