import React from 'react';
import {useStores} from '../../../../../../../hooks/useStores';

type DescriptionProps = {
  className?: string;
};

export const Description: React.FC<DescriptionProps> = ({className}) => {
  const {digitalAsset} = useStores();

  return <div className={className} dangerouslySetInnerHTML={{__html: digitalAsset?.description || ''}} />;
};
