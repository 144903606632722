import React from 'react';
import {AssetPageStore} from './AssetPageStore';
import {IHostProps} from '@wix/yoshi-flow-editor';

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export type AssetPageStoreProps = Awaited<ReturnType<AssetPageStore['toProps']>> & {
  host: IHostProps;
};

export const AssetPageContext = React.createContext<AssetPageStoreProps>({} as AssetPageStoreProps);
