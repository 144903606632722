import {useSettings} from '@wix/tpa-settings/react';
import settingsParams from '../components/AssetPage/settingsParams';

export function useDescriptionSettings() {
  const settings = useSettings();

  const shouldShowDescriptionInfo =
    settings.get(settingsParams.showDescription) && !settings.get(settingsParams.showDescriptionTab);
  const shouldShowDescriptionTab =
    settings.get(settingsParams.showDescription) && settings.get(settingsParams.showDescriptionTab);

  return {shouldShowDescriptionInfo, shouldShowDescriptionTab};
}
