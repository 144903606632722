import React from 'react';

export const DescriptionIcon = ({className}: {className?: string}) => (
  <div style={{display: 'flex', alignItems: 'center'}} className={className}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.001 17.001V18.001H6V17.001H18.001ZM18.001 13.001V14.001H6V13.001H18.001ZM18.001 9.00098V10.001H6V9.00098H18.001ZM18.001 5.00098V6.00098H6V5.00098H18.001Z"
        fill="black"
      />
    </svg>
  </div>
);
